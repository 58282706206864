import React, { useEffect, useState } from 'react';
import i18n from './i18n';
import axios from 'axios';
import Footer from './components/footer';
import HeaderActions from './components/headerActions';
import WhatsForm from './components/whatsForm';
import StrawContent from './components/strawContent';
import { withNamespaces } from 'react-i18next';
import './App.css';

function App({ t }) {
    const [state, setState] = useState({
        countryCode: '',
        deferredPrompt: null
    });

    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
        document.title = t('title');
    }

    useEffect(() => {
        const body = document.querySelector('body');

        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            setState({...state, deferredPrompt: e });
        });

        axios.get('https://ipapi.co/json/').then((response) => {
            if(typeof response === 'object'
            && response.data
            && typeof response.data === 'object') {
                const data = response.data;
                const langs = data.languages.split(',');
                const countryCode = data.country_calling_code.substring(1);
                changeLanguage(langs[0]);
                setState({...state, countryCode });
            }
        }).catch((error) => {
            console.log(error);
        });

        setTimeout(()=> {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
            body.classList.remove('is-preload');
        }, 500);
    }, []);

    useEffect(() => {});

    return (
        <div className="app">
            <header id="header">
                <div className="content">
                    <h1><a href="#">WhatsMe</a></h1>
                    <p>{t('subtitle_line_1')}<br />{t('subtitle_line_2')}</p>
                    <p>{t('header_description')} 😎</p>
                    <HeaderActions />
                </div>
                <div className="image phone">
                    <div className="inner">
                        <img src="images/screen.png" alt="" />
                    </div>
                </div>
            </header>

            <WhatsForm countryCode={state.countryCode} />
            <section className="wrapper">
                <StrawContent deferredPrompt={state.deferredPrompt} />
            </section>
            <Footer />
        </div>
    );
}

export default withNamespaces()(App);
