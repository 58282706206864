import React from 'react';
import { withNamespaces } from 'react-i18next';
import { FaCommentDots, FaCloudDownloadAlt, FaUserShield } from 'react-icons/fa';
import { isIpad, isIos } from '../../helpers';
import { Link } from "react-scroll";

function HeaderActions({ t }) {
    const styles = {
        button: {
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center'
        },
        icon: {
            marginRight: '10px'
        }
    };
    const isAlone = window.matchMedia('(display-mode: standalone)').matches;

    return (
        <ul className="actions">
            <li>
                <Link to="send" smooth={true} style={styles.button} className="button primary icon solid fa-send scrolly">
                    <FaCommentDots style={styles.icon} />
                    {t('send')}
                </Link>
            </li>
            {!isAlone && !isIpad() &&
                <li className="col-6">
                    <Link to="install" smooth={true} style={styles.button} className="button icon solid fa-chevron-down scrolly">
                        <FaCloudDownloadAlt style={styles.icon} />
                        {t('install')}
                    </Link>
                </li>
            }
            <li className="col-6">
                <Link to="privacy" smooth={true} style={styles.button} className="button icon solid fa-chevron-down scrolly">
                    <FaUserShield style={styles.icon} />
                    {t('moreInfo')}
                </Link>
            </li>
        </ul>
    );
}

export default withNamespaces()(HeaderActions);
