
export const isAlone = window.matchMedia('(display-mode: standalone)').matches;

export const isIos = () => {
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
    || (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
};

export const isAndroid = () => {
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('android') > -1; 
};

export const isIpad = () => {
    return (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || navigator.platform === 'iPad'
};
