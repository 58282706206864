import React from 'react';
import { withNamespaces } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';
import { BsPlusSquare } from 'react-icons/bs';
import { IoShareOutline } from 'react-icons/io5';

function IosPromptAddToHome({ t, showPromptInstall, hidePrompt }) {
    const styles = {
        div: {
            width: 'calc(100% - 40px)',
            color: '#fff',
            textAlign: 'left'
        },
        buttonClose: {
            border: 0,
            borderRadius: 0,
            width: '30px',
            boxShadow: 'none'
        }
    }
    const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);

    return (
        <React.Fragment>
            {showPromptInstall &&
                <div className="prompt-install-container">
                    <div style={styles.div}>
                        {isSafari &&
                            <React.Fragment>
                                <p className="align-center">{t('iOsAddmeHome_p1')}</p>
                                <p>
                                    {t('iOsAddmeHome_p2')} <IoShareOutline style={({margin: '1px 7px 0 7px', color: '#ffffff'})} />
                                    <br/>
                                    {t('iOsAddmeHome_p3')} <BsPlusSquare style={({margin: '1px 7px 0 7px', color: '#ffffff'})} />
                                </p>
                            </React.Fragment>
                        }
                        {!isSafari &&
                            <p>{t('iOs_notSafari')}</p>
                        }
                    </div>
                    <button style={styles.buttonClose} onClick={hidePrompt}>
                        <FaTimes style={({color: '#fff'})} />
                    </button>
                </div>
            }
        </React.Fragment>
    );
}

export default withNamespaces()(IosPromptAddToHome);
