import React, { useState, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import CountrySelector from '../countrySelector';

function WhatsForm({ countryCode, t }) {
    const [state, setState] = useState({
        country: countryCode,
        phone: ''
    });

    const setCountry = (val) => {
        setState({...state, country: val});
    };
    const setPhone = ({target}) => {
        const val = String(target.value);
        setState({...state, phone: val.trim()});
    };

    const sendMessage = () => {
        const { country, phone } = state;
        if(country !== '' && phone !== '') {
            window.open(`https://api.whatsapp.com/send?phone=${country}${phone}`, '_blank');
        }
    };

    useEffect(() => {
        setState({...state, country: countryCode});
    }, [countryCode]);

    return (
        <section id="send" className="wrapper special pb30">
            <header className="major">
                <h2>{t('formTitle')}</h2>
            </header>
            <div className="inner">
                <div className="content row gtr-uniform">
                    <div className="col-5">
                        <CountrySelector setCountry={setCountry.bind(this)} value={state.country} />
                    </div>
                    <div className="col-7">
                        <input type="number" pattern="\d*" value={state.phone} onChange={setPhone} placeholder="0000000000" />
                    </div>
                    <div className="col-12">
                        <ul className="actions">
                            <li>
                                <button type="button" className="primary" onClick={sendMessage}>{t('send')}</button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default withNamespaces()(WhatsForm);
