import React, { useState, useEffect } from 'react';
// import AndroidPromtInstall from '../androidPromtInstall';
import IosPromptAddToHome from '../iosPromptAddToHome';
import { withNamespaces } from 'react-i18next';
import { FaMobileAlt } from 'react-icons/fa';
import { isAlone, isIos, isIpad } from '../../helpers';

const styles = {
    button: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center'
    },
    icon: {
        marginRight: '10px'
    }
};

function StrawContent({ t, deferredPrompt }){
    const [state, setState] = useState({
        showPromptInstall: false,
        deferredPrompt: deferredPrompt
    });

    const hidePrompt = () => {
        setState({ showPromptInstall: false });
    };
    const showPrompt = () => {
        if(!isIos()) {
            if(state.deferredPrompt) {
                state.deferredPrompt.prompt();
            } else {
                alert(t('androidPromptError'));
            }
        } else {
            setState({ showPromptInstall: true });
        }
    };

    useEffect(() => {
        setState({...state, deferredPrompt: deferredPrompt});
    }, [deferredPrompt]);

    return (
        <div className="inner alt">

            <section id="about" className="spotlight">
                <div className="image"><img src="images/kia.jpg" alt="" /></div>
                <div className="content">
                    <h2>{t('body_desc_title')}</h2>
                    <p>{t('body_desc_1')}</p>
                    <p>{t('body_desc_2')}</p>
                    <p>{t('body_desc_3')}</p>
                </div>
            </section>

            {!isAlone && !isIpad() &&
                <section id="install" className="spotlight">
                    <div className="image"><img src="images/muffin.jpg" alt="" /></div>
                    <div className="content">
                        <h2>{t('instala_title')}</h2>
                        <p>{t('instala_p1_1')}<br/>{t('instala_p1_2')}</p>
                        <ul className="actions">
                            <li>
                                <button style={styles.button} className="button primary fit small icon" onClick={showPrompt}>
                                    <FaMobileAlt style={styles.icon} />
                                    {t('Install_WhatsMe')}
                                </button>
                                {isIos() && <IosPromptAddToHome
                                    hidePrompt={hidePrompt.bind(this)}
                                    showPromptInstall={state.showPromptInstall}
                                />}
                            </li>
                        </ul>
                    </div>
                </section>
            }

            <section id="privacy" className="spotlight">
                <div className="image"><img src="images/romi.jpg" alt="" /></div>
                <div className="content">
                    <h2>{t('privacidad_title')}</h2>
                    <p>{t('privacidad_p1')}</p>
                    <p>{t('privacidad_p2_1')} <strong>{t('privacidad_p2_2')}</strong> {t('privacidad_p2_3')}</p>
                    <p>{t('privacidad_p3')} 👇</p>
                </div>
            </section>
            <section>
                <div className="content">
                    <h2 className="align-center"></h2>
                    <p>{t('consejos_p1_1')}<br /><i>{t('consejos_p1_2')}</i></p>
                    <p>{t('consejos_p2_1')}<br /><i>{t('consejos_p2_2')}</i></p>

                    <p className="align-center">{t('consejos_p3')} 😧</p>
                    <p>{t('consejos_p4')}</p>
                    <p className="align-center">🥳 {t('consejos_p5')}</p>
                    <p>{t('consejos_p6_1')}<br/>{t('consejos_p6_2')}</p>
                    <p className="align-center">{t('consejos_p7_1')} <strong>{t('consejos_p7_2')}</strong> {t('consejos_p7_3')}</p>
                </div>
            </section>
        </div>
    );
}
export default withNamespaces()(StrawContent);
