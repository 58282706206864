import React from 'react';
import { withNamespaces } from 'react-i18next';
import { FaFacebookF, FaMandalorian } from 'react-icons/fa';

function Footer({ t }) {
    return (
        <footer id="footer">
            <p>{t('productBy')} <a href="https://polilla.studio" target="_blank" rel="noreferrer">Polilla Studio</a></p>
            <ul className="icons">
                <li>
                    <a href="https://www.facebook.com/Polilla.Studio" className="icon brands" target="_blank" rel="noreferrer">
                        <FaFacebookF />
                        <span className="label">Facebook</span>
                    </a>
                </li>
                <li>
                    <a href="https://polilla.studio" className="icon brands" target="_blank" rel="noreferrer">
                        <FaMandalorian />
                        <span className="label">Website</span>
                    </a>
                </li>
            </ul>
            <p className="copyright">
                &copy; {t('copyright')}
                <br></br>
                {t('templateCredits')} <a href="http://html5up.net" target="_blank" rel="nofollow noopener noreferrer">HTML5 UP</a>
            </p>
            <p className="copyright">{t('perritos')}</p>
            <p className="copyright">{t('freeService')}</p>
        </footer>
    );
}

export default withNamespaces()(Footer);
